var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { cols: "12", sm: "7" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "my-3",
                      attrs: { color: "primary" },
                      on: {
                        click: function () {
                          this$1.$router.go(-1)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "pa-4" },
                    [
                      _c("v-toolbar", { staticClass: "secondary ma-4" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "v-toolbar-title",
                              { staticClass: "white--text text-h5" },
                              [
                                _c("v-card-title", [
                                  _vm._v(" Cancel Subscription "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mx-4 mt-6 pt-2" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Company",
                              dense: "",
                              outlined: "",
                              disabled: "",
                              filled: "",
                            },
                            model: {
                              value: _vm.form.company,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "company", $$v)
                              },
                              expression: "form.company",
                            },
                          }),
                          _vm.companySubscriptionOptions != null
                            ? _c("v-autocomplete", {
                                attrs: {
                                  "return-object": "",
                                  items: _vm.companySubscriptionOptions,
                                  outlined: "",
                                  dense: "",
                                  "small-chips": "",
                                  "item-value": "stripe_id",
                                  "item-text": "plan_name",
                                  "deletable-chips": "",
                                  label: "Plan Name",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.plan_name) +
                                                " ( RM " +
                                                _vm._s(item.price) +
                                                ") "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2803058088
                                ),
                                model: {
                                  value: _vm.form.stripeSubscription,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "stripeSubscription",
                                      $$v
                                    )
                                  },
                                  expression: "form.stripeSubscription",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div"),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", plain: "" },
                              on: { click: _vm.redirectBack },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-3",
                              attrs: { color: "primary" },
                              on: { click: _vm.validateInput },
                            },
                            [_vm._v(" Confirm ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }